import React from 'react'
// nodejs library that concatenates classes
// @material-ui/core components
// React icons
// core components
import Header from 'components/Header/Header.jsx'
import Footer from 'components/Footer/Footer.jsx'
import { graphql, useStaticQuery } from 'gatsby'

import PageHeaderFull from '../../components/PageHeaderFull/PageHeaderFull'
import ThreeArgumentsSection from '../../elements/threeArgumentsSection/threeArgumentsSection'
import Section from '../../components/Section/Section'
import Content from '../../elements/Content/Content'
import Impressionen from '../../elements/Impressionen/Impressionen'
import SpainHolidaysCta from '../../elements/SpainholidaysCta/SpainHolidaysCta'
import BlogSection from '../../elements/RecentBlogPosts/RecentBlogPosts'


const FerienhausGreen11 = props => {
  const data = useStaticQuery(graphql`
    query {
      datoCmsFerienhausGreen{
        slug
        title
        subtitle
        backgroundImage{
          alt
          fluid(maxWidth:1800){
            src
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
     
        arguments{
          title
          subtitle
          image{
            alt
            fluid(maxWidth:500){
              src
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
        spainholidayBanner{
          title
          subtitle
          buttonTitle
          buttonLink
        }
        content{
          __typename
          ...on DatoCmsH2{
            heading
          }
          ...on DatoCmsH3{
            heading
          }
          ...on DatoCmsH4{
            heading
          }
          ...on DatoCmsH5{
            heading
          }
          ...on DatoCmsH6{
            heading
          }
          ...on DatoCmsText{
            text
          }
          ...on DatoCmsImage{
            image{
              alt
              fluid(maxWidth:900){
                src
                ...GatsbyDatoCmsFluid }
            }
          }

        }
        impressions{
          title
          subtitle
          images{
            alt
            fluid(maxWidth:1800){
              src
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }

      }
    }
  `)


  return (
    <div>
      <Header
        absolute
        light
        SEOtitle={data.datoCmsFerienhausGreen.title}
        SEOdescription={data.datoCmsFerienhausGreen.subtitle}
        SEOimage={data.datoCmsFerienhausGreen.backgroundImage.fluid.src}
        SEOpathname={data.datoCmsFerienhausGreen.slug}
      />
      <PageHeaderFull

        image={data.datoCmsFerienhausGreen.backgroundImage.fluid}

        subtitle={data.datoCmsFerienhausGreen.subtitle}
        alt={data.datoCmsFerienhausGreen.backgroundImage.alt}
      />
      <ThreeArgumentsSection
        tall
        makeTitleH1
        title={data.datoCmsFerienhausGreen.title}
        threeArguments={data.datoCmsFerienhausGreen.arguments}
      />
      <Section innerStyles={{ maxWidth: 705 }} paddingTop={80} paddingBottom={80}>
        <Content
          content={data.datoCmsFerienhausGreen.content}
        />
      </Section>
      <Impressionen
        title={data.datoCmsFerienhausGreen.impressions[0].title}
        subtitle={data.datoCmsFerienhausGreen.impressions[0].subtitle}
        images={data.datoCmsFerienhausGreen.impressions[0].images}

      />
      <SpainHolidaysCta
        title={data.datoCmsFerienhausGreen.spainholidayBanner[0].title}
        subtitle={data.datoCmsFerienhausGreen.spainholidayBanner[0].subtitle}
        buttonTitle={data.datoCmsFerienhausGreen.spainholidayBanner[0].buttonTitle}
        buttonLink={data.datoCmsFerienhausGreen.spainholidayBanner[0].buttonLink}

      />
      <BlogSection/>
      <Footer/>
    </div>
  )
}

export default FerienhausGreen11
