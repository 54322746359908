import { section } from 'assets/jss/material-kit-react.jsx'


const contentStyle = {
  section: {
    ...section,
    marginBottom: 60,

    backgroundColor: '#fff',
  },
  root: {
    width: '100%',
  },
  h2style: {
    textAlign: 'left',
    fontFamily: '"Cormorant Garamond", "Helvetica", "Arial", sans-serif',
    color: '#1a1a1a',
    margin: 0,
    padding: '20px 0 12px',
    fontSize: 36,
    lineHeight: 1.06,
    letterSpacing: -1,
    fontWeight: 500,
    '@media (max-width: 768px)': {
      fontSize: 28,
      padding: '6px 0 9px',
    },
  },
  h3style: {
    textAlign: 'left',
    fontFamily: '"Cormorant Garamond", "Helvetica", "Arial", sans-serif',
    color: '#1a1a1a',
    margin: 0,
    padding: '15px 0 10px',
    fontSize: 32,
    lineHeight: 1.06,
    letterSpacing: -1,
    fontWeight: 500,
    '@media (max-width: 768px)': {
      fontSize: 24,
      padding: '5px 0 8px',
    },
  },
  h4style: {
    textAlign: 'left',
    fontFamily: '"Cormorant Garamond", "Helvetica", "Arial", sans-serif',
    color: '#1a1a1a',
    margin: 0,
    padding: '0 0 6px',
    fontSize: 28,
    lineHeight: 1.43,
    letterSpacing: -1,
    fontWeight: 500,
    '@media (max-width: 768px)': {
      fontSize: 22,
      padding: '5px 0 8px',
    },
  },
  h5style: {
    textAlign: 'left',
    fontFamily: '"Cormorant Garamond", "Helvetica", "Arial", sans-serif',
    color: '#1a1a1a',
    margin: 0,
    padding: '6px 0',
    fontSize: 22,
    lineHeight: 1.45,
    letterSpacing: 1,
    fontWeight: 600,
    '@media (max-width: 768px)': {
      fontSize: 20,
      padding: '3px 0 0px',
    },
  },
  h6style: {
    textAlign: 'left',
    fontFamily: '"Cormorant Garamond", "Helvetica", "Arial", sans-serif',
    color: '#1a1a1a',
    margin: 0,
    padding: '6px 0',
    fontSize: 22,
    lineHeight: 1.45,
    letterSpacing: 1,
    fontWeight: 600,
    '@media (max-width: 768px)': {
      fontSize: 18,
      padding: '3px 0 0px',
    },
  },
  youtube: {
    marginBottom: 30,
    minHeight: 350,
    '@media (max-width: 768px)': {
      minHeight: 300,
    },
    '@media (max-width: 400px)': {
      minHeight: 200,
    },
  },

  contentWrapper: {
    '& table': {
      fontSize: 24,
      lineHeight: 1.65,
      '@media (max-width: 768px)': {
        fontSize: 20,
      },
    },
    '& p': {
      fontFamily: '"Cormorant Garamond", sans-serif',
      padding: 0,
      margin: 0,
      color: '#1a1a1a',
      textAlign: 'left',
      fontSize: 24,
      lineHeight: 1.65,
      paddingBottom: 26,
      '@media (max-width: 768px)': {
        fontSize: 20,
      },
    },
    '& bold': {
      fontWeight: 'bold',
    },
    '& ul': {
      fontFamily: '"Cormorant Garamond", sans-serif',
      fontSize: 24,
      textAlign: 'left',
      listStyleType: 'inherit',

      paddingBottom: 15,
      '@media (max-width: 768px)': {
        fontSize: 20,

      },
    },
    '& ol': {

      fontFamily: '"Cormorant Garamond", sans-serif',
      fontSize: 24,
      textAlign: 'left',
      listStyleType: 'upper-roman',
      paddingBottom: 15,
      // list-style-type: upper-roman;
      '@media (max-width: 768px)': {
        fontSize: 20,

      },
    },
    '& li': {
      paddingTop: 8,
      textAlign: 'left',
      fontWeight: 'bold',
    },
    '& a': {
      color: '#1a1a1a',
      borderBottom: '1px solid #1a1a1a',
      transition: 'border-bottom 0.4s ease',

      '&:hover': {
        borderBottom: '1px solid transparent',
      },

    },
    '& blockquote': {
      margin: 0,
      padding: 0,
      '& p': {
        textAlign: 'left',
        fontFamily: '"Cormorant Garamond", sans-serif',
        color: '#1a1a1a',
        margin: 0,
        paddingBottom: 26,
        fontSize: 36,
        fontStyle: 'italic',

        lineHeight: 1.06,
        '@media (max-width: 768px)': {
          fontSize: 28,
        },

      },
    },

  },


  rawRoot: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    backgroundColor: '#fff',
    alignItems: 'center',

  },
  imageClass: {
    height: '100%',
    width: '100%',
  },
  title: {
    margin: 0,
    padding: 0,
    paddingBottom: 40,
    fontSize: 48,
  },
  date: {

    fontFamily: '"Cormorant Garamond", "Helvetica", "Arial", sans-serif',
    margin: 0,

    fontSize: 18,
    color: '#1a1a1a',
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
  },

}

export default contentStyle
